import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { getFileDisplayName, useLanguage } from '@orus.eu/pharaoh'
import { dynamicFileNames } from '../../../../../../lib/download-filenames'
import { getAdminContractDownloadLink } from '../../../../../../lib/download-links'
import { formatDateTime } from '../../../../../../lib/format'
import { RenewalEndorsementTable, type RenewalEndorsementTableRowData } from './RenewalEndorsementTable'

export const ContractVersionsTable = function ContractVersionsTable({
  contract,
  customerEmail,
}: {
  contract: BackofficeContractDescription
  customerEmail: string
}): JSX.Element {
  const language = useLanguage()

  const tableData = contract.versions
    .slice()
    .reverse()
    .map((contractVersion, index): RenewalEndorsementTableRowData => {
      const fileName = dynamicFileNames['agreed-terms'](customerEmail, language)
      return {
        dueDate: formatDateTime(contractVersion.startTimestamp),
        paymentAmount: checkDefinedAndNotNull(
          contractVersion.dimensions.quote?.paymentRecurrence === 'yearly'
            ? contractVersion.dimensions.quote?.yearlyTotalPremium
            : contractVersion.dimensions.quote?.subsequentMonthsTotalPremium,
        ),
        paymentRecurrence: checkDefinedAndNotNull(contractVersion.dimensions.paymentRecurrence),
        status: { label: 'Actif', variant: 'success', icon: 'circle-check-regular' },

        downloadLink: getAdminContractDownloadLink(contract.subscriptionId, contractVersion.signature.id),
        fileName,
        downloadTooltip: `${getFileDisplayName(fileName)} - Version ${contract.versions.length - index}`,
      }
    })

  return <RenewalEndorsementTable tableData={tableData} emptyStateSubtitle="Aucun contrat disponible" />
}
