import { css } from '@emotion/react'
import type { GuaranteesDescription } from '@orus.eu/backend/src/routers/contracts'
import { spacing, Text } from '@orus.eu/pharaoh'
import { memo, useMemo } from 'react'
import { GuaranteeList } from '../../../../../organisms/guarantee/guarantee-list'
import { OptionalGuaranteeList } from '../../../../../organisms/guarantee/option-list'

export const ContractGuaranteesList = memo<{ guaranteesDescription: GuaranteesDescription }>(
  function ContractGuaranteeList({ guaranteesDescription }) {
    const activeOptions = useMemo(
      () => guaranteesDescription.optionalGuaranteesDescription.filter((option) => option.isActive),
      [guaranteesDescription.optionalGuaranteesDescription],
    )

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[50]};
        `}
      >
        <Text variant="subtitle1">Garanties incluses</Text>
        <GuaranteeList guarantees={guaranteesDescription.guaranteesDescription} />
        <Text variant="subtitle1">Options activées</Text>
        {activeOptions.length === 0 ? (
          <Text variant="body2">Aucune option active</Text>
        ) : (
          <OptionalGuaranteeList optionalGuarantees={activeOptions} />
        )}
      </div>
    )
  },
)
