import { css } from '@emotion/react'
import { spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../../../../../../client'
import { GlobalLoadingState } from '../../../../../../molecules/global-loading-state'
import { InvoiceList } from '../../../../../../organisms/invoicing/invoice-list'

export const ContractInvoicesList = memo<{ subscriptionId: string }>(function ContractInvoicesList({ subscriptionId }) {
  const { isLoading, data } = trpcReact.invoices.listInvoices.useQuery(subscriptionId)
  if (isLoading) return <GlobalLoadingState />
  if (!data) return undefined
  return (
    <div
      css={css`
        margin-top: ${spacing[50]};
      `}
    >
      <InvoiceList invoicingItems={data} />
    </div>
  )
})
